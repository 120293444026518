import React, { Component } from 'react';
import './App.css';
import { isMobile } from './utils.js';

export const navy = '#689';

class Header extends Component {
  render() {
    return (
      <div
        className={'ui inverted header welterweight'}
        style={{ paddingTop: isMobile() ? '.618em' : ''}}>
        {this.props.name}
      </div>
    )
  }
}

class Note extends Component {
  render() {
    return (
      <div style={{ position: 'absolute', top: -10 }}>
        <p>Hello</p>
      </div>
    )
  }
}

class Products extends Component {
  render() {
    let apps = [
      [1, 'Jotpocket',  'Notes and pics, fast af', 'iOS', '/logo_jotpocket.png', 'https://itunes.apple.com/us/app/jotpocket/id1109911349?mt=8', true],
      [2, 'HailMe', 'Get someone\'s attention', 'iOS', '/logo_hailme.png', 'https://itunes.apple.com/us/app/hailme/id956407355?mt=8', true],
      [3, 'Salad Bowl App', 'Timer & score keeper', 'Web', '/logo_saladbowl.png', 'http://saladbowlapp.com/', true],
      [0, 'TD4W', 'A party in your pocket', 'iOS', '/logo_td4w.png', 'https://itunes.apple.com/us/app/td4w-turn-down-for-what/id912651703?mt=8', false]
    ]
    return (
      <div className={'column'}>
        <Header name={'Products'} />
        <div className={'ui very relaxed ' + (isMobile() ? '' : 'selection' ) + ' inverted list'}>
          {apps.map((i) => {
            return (
              <a
                key={i[0]}
                className={ (i[6] ? '' : 'disabled crossedOut ') + ' item'}
                href={i[5]}
              >
                <img
                  className={'ui mini rounded image'}
                  src={i[4]}
                  alt={'app icon for ' + i[1]}
                />
                <div className={'content'}>
                  <div className={'header'}>
                    {i[1]}
                  </div>
                  <div className={'description'}>
                    {i[2]} &sdot; {i[3]}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

class People extends Component {
  render() {
    let peeps = [
      [0, 'Abram Dawson', '@abram', '/avatar_ad.jpg', 'https://twitter.com/abram'],
      [1, 'Matt Baker', '@baker', '/avatar_mb.jpg', 'https://twitter.com/baker'],
      // [2, 'Kartik Talwar', '@TheRealKartik', 'https://avatars2.githubusercontent.com/u/461702?s=400&v=4', 'https://twitter.com/TheRealKartik'],
    ]
    return (
      <div className={'column'}>
        <Header name={'People'} />
        <div className={'ui very relaxed ' + (isMobile() ? '' : 'selection' ) + ' inverted list'}>
          {peeps.map((i) => {
            return (
              <a
                key={i[0]}
                className={'item'}
                href={i[4]}
              >
                <img
                  className={'ui mini circular image'}
                  src={i[3]}
                  alt={'app icon for ' + i[1]}
                />
                <div className={'content'}>
                  <div className={'header'}>
                    {i[2]}
                  </div>
                  <div className={'description'}>
                    {i[1]}
                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

class RightMenu extends Component {
  render() {
    let item = [
      // ['help',    'http://batterygray.com/support/'],
      ['twitter', 'https://twitter.com/BatteryGray'],
      ['dollar',  'https://cash.me/$batterygray'],
    ]
    return (
      <div className={'right menu'}>
        {item.map((i) => {
          return (
            <a
              key={i[0]}
              className={'icon item'}
              href={i[1]}>
              <i className={'inverted grey ' + i[0] + ' icon'} />
            </a>
          )
        })}
      </div>
    );
  }
}

class BGIcon extends Component {
  render() {
    return (
      <img
        src={'/logo_bg.png'}
        className={'ui ' + (isMobile() ? 'mini' : 'tiny') + ' image'}
        alt={'bg_icon'}
      />
    );
  }
}

class App extends Component {
  render() {
    return (
      <div className={'ui text container'} style={{ paddingTop: '2em' }}>
        {this.renderNote}
        {isMobile() && (
        <div
          className={'ui top fixed borderless inverted menu'}
          style={{ boxShadow: '0px 1px 18px rgba(0,0,0,0.05)', backgroundColor: navy }}>
          <div className={'item'}>
            <div className={'ui large inverted header'}>Battery Gray</div>
          </div>
          <RightMenu/>
        </div>
        )}
        <div className={'ui stackable grid'}>
          {!isMobile() && (
          <div className={'two wide column'}>
            <BGIcon/>
          </div>
          )}
          <div className={'fourteen wide column'}>
            {!isMobile() && (
            <div className={'ui fluid secondary menu'}>
              <div className={'fitted item'}>
                <div
                  className={'ui huge inverted header'}
                  style={{ paddingTop: '.4em', fontSize: '240%' }}>
                  Battery Gray
                </div>
              </div>
              <RightMenu/>
            </div>
            )}
            <div className={'ui stackable grid'}>
              <div className={'sixteen wide column'}>
                We're a small, San Francisco-based team that builds digital products &amp; experiences.
              </div>
              <div className={'two column row'}>
                <Products/>
                <People/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
